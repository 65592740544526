import { IconButton, Box, Grid, Button } from "@mui/material";

import { GB, NL } from 'country-flag-icons/react/3x2'

const MainMenu = ({ lang, onSetDifficulty, onChangeLanguage }) => {
  const Title = () => {
    return (
      <Box
        sx={{
          font: "normal normal bold 87px/102px Raleway",
          textAlign: "center",
          textTransform: "uppercase",
          textShadow: "0px 3px 6px #00000029",
          height: "190px",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        Quiz
      </Box>
    )
  }

  const LanguageSelect = () => {
    return (
      <Grid item xs={12} spacing={30} marginTop={20}>
        <Grid container justifyContent="center">
          <IconButton onClick={() => onChangeLanguage("NL")}>
            <Grid container justifyContent="center" sx={{
              font: "normal normal bold 23px/27px Raleway;",
              color: "white"
            }}>
              <Grid item xs={12}>NED</Grid>
              <Grid item><NL title="Nederlands" height="64px" /></Grid>
            </Grid>
          </IconButton>

          <IconButton onClick={() => onChangeLanguage("EN")}>
            <Grid container justifyContent="center" sx={{
              font: "normal normal bold 23px/27px Raleway;",
              color: "white"
            }}>
              <Grid item xs={12}>ENG</Grid>
              <Grid item><GB title="English" height="64px" /></Grid>
            </Grid>
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  const MenuText = () => {
    return (
      <Grid item xs={12} marginTop={10}>
        <Grid container justifyContent="center">
          <Grid item xs={6.5} sx={{
            font: "normal normal normal 26px/30px Space Grotesk;",
            textAlign: "center"
          }}>
            {lang === "NL" ?
              "Hoe goed ken jij de Amsterdamse grachten? Test je kennis in de Waterkracht Quiz!"
              : "How well do you know the Amsterdam canals? Test your knowledge in the Waterkracht Quiz!"}
          </Grid>
        </Grid>

      </Grid>
    )
  }

  const DifficultyButtons = () => {
    return (
      <Grid xs={12} marginTop="475px">
        <Grid container justifyContent="center">
          <Grid item xs={8}>
            <Button sx={{
              color: "black",
              background: "white",
              borderRadius: "34px",
              height: "64px",
              font: "normal normal bold 36px/42px Raleway",
              letterSpacing: "0px",
              width: "191px",
              height: "117px",
            }}
              onClick={() => onSetDifficulty("EASY")}
            >
              {lang === "NL" ? "Makkelijk" : "Easy"}
            </Button>

            <Button sx={{
              float: "right",
              color: "black",
              background: "white",
              borderRadius: "34px",
              height: "64px",
              font: "normal normal bold 36px/42px Raleway",
              letterSpacing: "0px",
              width: "191px",
              height: "117px",
            }}
              onClick={() => onSetDifficulty("HARD")}
            >
              {lang === "NL" ? "Moeilijk" : "Difficult"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Title />
      <Grid container justifyContent="center">
        <LanguageSelect />
        <MenuText />
        <DifficultyButtons />
      </Grid>
    </div>
  )
}

export default MainMenu;