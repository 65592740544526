import { Button, Grid } from "@mui/material";
import SlideShow from "./SlideShow";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";

const Answer = ({ title, correct, index, onClick, answered, selected }) => {

  let background = "white"
  let border = "none"

  if (answered) {
    background = correct ? "#E0FAE7" : "#FFADAD"
    border = correct ? "solid 1px #09D840" : "solid 1px #E80000"
  }

  return (
    <Grid xs={12} item sx={{
      background: background,
      border: border,
      color: "black",
      textAlign: "center",
      font: "normal normal normal 26px/30px Space Grotesk;",
      letterSpacing: "0px",
      borderRadius: "47px",
      padding: "20px",
      marginBottom: 5,
      cursor: "pointer",
    }}
      onClick={(e) => { onClick(e); }}
    >
      {selected && answered && <IconButton sx={{
        color: correct ? "#10D82B" : "#FF0606",
        fontSize: "50px"
      }}>
        {correct ? <CheckIcon fontSize="30px" /> : <CloseIcon fontSize="30px" />}
      </IconButton>}
      {`${String.fromCharCode(index + 65)}: ${title}`}
    </Grid>
  )
}

const Extra = ({ text }) => {
  return (
    <Grid xs={12} item sx={{
      color: "white",
      textAlign: "center",
      font: "normal normal normal 26px/30px Space Grotesk;",
      letterSpacing: "0px",
      padding: "30px",
      marginBottom: 5
    }}>
      {text}
    </Grid>
  )
}

const NextButton = ({ onClick, lang }) => {
  return (
    <Button sx={{
      color: "black",
      background: "white",
      position: "absolute",
      right: "100px",
      borderRadius: "47px",
      height: "64px",
      font: "normal normal normal 26px/88px Space Grotesk",
      width: "298px",
      textTransform: "None"
    }}
      onClick={onClick}
      endIcon={<ArrowForwardIosIcon />}
    >
      {lang === "NL" ? "Volgende vraag" : "Next question"}
    </Button>
  )
}

const RestartButton = ({ onClick, lang }) => {
  return (
    <Button sx={{
      color: "white",
      background: "black",
      borderRadius: "47px",
      height: "64px",
      font: "normal normal normal 26px/88px Space Grotesk",
      width: "298px",
      textTransform: "None"
    }}
      onClick={onClick}
    >
      {lang === "NL" ? "Begin opnieuw" : "Restart"}
    </Button>
  )
}

function shuffle(array) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


const QuestionDisplay = ({ question, onAnswer, answered, onNext, onRestart, lang, multimedia, selectedIndex }) => {

  return (
    <Grid container justifyContent={"center"} sx={{
      height: "calc(100vh - 190px)", alignItems: "center", display: "flex"
    }}>

      <Grid xs={10} item>
        {multimedia && (!answered || question?.extra === "") && <Grid xs={12} item sx={{
          background: "None",
          color: "white",
          textAlign: "center",
          font: "normal normal normal 26px/30px Space Grotesk;",
          letterSpacing: "0px",
          borderRadius: "47px",
          padding: "30px",
          marginBottom: 5
        }}>
          {multimedia?.type === "image" && <img src={multimedia?.src} alt={question?.title} />}
          {multimedia?.type === "video" && <video src={multimedia?.src} loop autoPlay muted style={{ objectFit: "fill", width: "100%" }} />}
          {multimedia?.type === "slideshow" && <SlideShow images={multimedia?.src} interval={2500} />}
        </Grid>}

        {answered && question?.extra !== "" && <Extra text={question?.extra} />}


        <Grid xs={12} item sx={{
          background: "black",
          color: "white",
          textAlign: "center",
          font: "normal normal normal 26px/30px Space Grotesk;",
          letterSpacing: "0px",
          borderRadius: "47px",
          padding: "30px",
          marginBottom: 5
        }}>
          {`${question?.id + 1}. ${question?.title}`}
        </Grid>


        {(question?.answers).map(({ title, correct }, index) => {
          return (
            <Answer
              key={index}
              title={title}
              correct={correct}
              index={index}
              answered={answered}
              selected={index === selectedIndex}
              onClick={() => { onAnswer(correct, index) }} />
          )
        })}
        {answered && <NextButton onClick={onNext} lang={lang} />}
        {answered && <RestartButton onClick={onRestart} lang={lang} />}
      </Grid>
    </Grid>
  )
}

export default QuestionDisplay;