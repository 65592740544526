
// easy
import easy1 from "../../static/images/easy1.png";
import easy2 from "../../static/images/easy2.png";
import easy3 from "../../static/images/easy3.png";
import easy4_1 from "../../static/images/easy4_1.png";
import easy4_2 from "../../static/images/easy4_2.png";
import easy4_3 from "../../static/images/easy4_3.png";
import easy5 from "../../static/images/easy5.png";
import easy6 from "../../static/images/easy6.png";
import easy7 from "../../static/images/easy7.png";
import easy8 from "../../static/images/easy8.png";
import easy10 from "../../static/images/easy10.png";

// hard
import hard1 from "../../static/images/hard1.png"
import hard2 from "../../static/images/hard2.png"
import hard3 from "../../static/images/hard3.png"
import hard4 from "../../static/images/hard4.png"
import hard5 from "../../static/images/hard5.png"
import hard6 from "../../static/images/hard6.png"
import hard7 from "../../static/images/hard7.png"
import hard9 from "../../static/images/hard9.png"
import hard10 from "../../static/images/hard10.png"

import arviVideo from "../../static/images/arvi.mp4"


const Images = {
  EASY: {
    0: { src: easy1, type: "image" },
    1: { src: easy2, type: "image" },
    2: { src: easy3, type: "image" },
    3: { src: [easy4_1, easy4_2, easy4_3], type: "slideshow" },
    4: { src: easy5, type: "image" },
    5: { src: easy6, type: "image" },
    6: { src: easy7, type: "image" },
    7: { src: easy8, type: "image" },
    8: { src: arviVideo, type: "video" },
    9: { src: easy10, type: "image" },
  },
  HARD: {
    0: { src: hard1, type: "image" },
    1: { src: hard2, type: "image" },
    2: { src: hard3, type: "image" },
    3: { src: hard4, type: "image" },
    4: { src: hard5, type: "image" },
    5: { src: hard6, type: "image" },
    6: { src: hard7, type: "image" },
    7: { src: arviVideo, type: "video" },
    8: { src: hard9, type: "image" },
    9: { src: hard10, type: "image" },
  }
}

export default Images;